import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contracts: [],
  contract: "",
  contractId: null,
  contractStatus: null,
  contractStatuses: [],
  contractDocTypes: [],
};

export const contractSlice = createSlice({
  name: "contract",
  initialState,
  reducers: {
    setContracts: (state, action) => {
      state.contracts = action.payload;
    },
    removeContracts: (state, action) => {
      state.contracts = [];
    },
    setContract: (state, action) => {
      state.contract = action.payload;
    },
    setContractId: (state, action) => {
      state.contractId = action.payload;
    },
    removeContractId: (state, action) => {
      state.contractId = null;
    },
    addContracts: (state, action) => {
      state.contracts = [...state.contracts, action.payload];
    },
    updateContracts: (state, action) => {
      state.contracts = state.contracts.map((item) =>
        item.id === action.payload.id ? action.payload : item
      );
    },
    setContractStatus: (state, action) => {
      state.contractStatus = action.payload;
    },
    removeContractStatus: (state, action) => {
      state.contractStatus = null;
    },
    setContractStatuses: (state, action) => {
      state.contractStatuses = action.payload;
    },
    setContractDocTypes: (state, action) => {
      state.contractDocTypes = action.payload;
    },
  },
});

export const {
  setContracts,
  removeContracts,
  setContract,
  setContractId,
  removeContractId,
  addContracts,
  updateContracts,
  setContractStatus,
  removeContractStatus,
  setContractStatuses,
  setContractDocTypes,
} = contractSlice.actions;

export default contractSlice.reducer;

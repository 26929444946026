export const getRequestActStyles = (statusId) => {
  switch (statusId) {
    case 1: //Импорт
      return {
        borderColor: "rgba(76, 175, 80, 0.5)",
        backgroundColor: "rgba(76, 175, 80, 0.1)",
        color: "rgba(56, 142, 60, 0.8)",
      };
    case 2: // Экспорт
      return {
        borderColor: "rgba(255, 152, 0, 0.5)",
        backgroundColor: "rgba(255, 152, 0, 0.1)",
        color: "rgba(251, 140, 0, 0.8)",
      };
    default:
      return null;
  }
};

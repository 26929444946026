import styles from "./BodyHeaderContracts.module.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const BodyHeaderContracts = ({
  chosenOption,
  onChangeFunc,
  list,
  handleOpen,
  text,
  buttonText,
  contract,
  contracts,
  loading,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.h2}>
        <h2>{text}</h2>
      </div>
      <FormControl
        sx={{ m: 1, width: 600, minWidth: 200 }}
        disabled={(!contract || loading || !contracts.length) && true}
      >
        <InputLabel>{text}</InputLabel>
        <Select
          value={list && list.length > 0 ? chosenOption?.id || "" : ""}
          onChange={onChangeFunc}
          input={<OutlinedInput label="Выберите компанию" />}
          MenuProps={MenuProps}
        >
          {list &&
            list.map((item, index) => (
              <MenuItem key={index} value={item.id}>
                {item.contract_number}
              </MenuItem>
            ))}
        </Select>
      </FormControl>

      <Button
        variant="contained"
        endIcon={<AddIcon />}
        sx={{
          minWidth: "140px",
          minHeight: "50px",
          backgroundColor: "rgba(28, 107, 31, 0.7)",
          "&:hover": {
            backgroundColor: "rgba(28, 107, 31, 0.8)",
          },
        }}
        onClick={handleOpen}
        disabled={loading}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default BodyHeaderContracts;

export const getProfileStatusStyles = (statusId) => {
  switch (statusId) {
    case 1:
      return {
        borderColor: "rgba(33, 150, 243, 0.5)",
        backgroundColor: "rgba(33, 150, 243, 0.1)",
        color: "rgba(0, 81, 148, 0.8)",
      };
    case 2:
      return {
        borderColor: "rgba(255, 165, 0, 0.5)",
        backgroundColor: "rgba(255, 165, 0, 0.1)",
        color: "rgba(255, 165, 0, 0.8)",
      };
    case 3:
      return {
        borderColor: "rgba(5, 172, 133, 0.5)",
        backgroundColor: "rgba(5, 172, 133, 0.1)",
        color: "rgba(5, 172, 133, 0.8)",
      };
    default:
      return null;
  }
};

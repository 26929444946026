import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import styles from "./RequestsTable.module.scss";

const RequestsTable = ({ headers, rows, handleRowClick }) => {
  return (
    <div className={styles.container}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: "#2f82d5",
                    color: "#fff",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index} hover onClick={() => handleRowClick(row)}>
                <TableCell component="th" scope="row">
                  {row.number_request}
                </TableCell>
                <TableCell>{row.request_date}</TableCell>
                <TableCell>{row.status}</TableCell>
                <TableCell>{row.total ? row.total : "---"}</TableCell>
                <TableCell>{row.currency}</TableCell>
                <TableCell>
                  {row.activity_type_title ? row.activity_type_title : "---"}
                </TableCell>
                <TableCell>{row.curs ? row.curs : "---"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RequestsTable;

import styles from "./AllRequestsPage.module.scss";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ThreeCircles } from "react-loader-spinner";
import { allHeaders, requestOptions } from "../../data/requestsPageOptions";
import {
  setAuthData,
  setExpirationMessage,
  setExpirationWarnOpen,
  setIsAuth,
} from "../../redux/slices/authSlice";
import Cookies from "js-cookie";
import { authAPI } from "../../api/authAPI";
import { requestAPI } from "../../api/requestAPI";
import moment from "moment";
import AllRequestsTable from "../../components/AllRequestsTable/AllRequestsTable";
import ModalsContext from "../../contexts/ModalsContext";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Button, Tooltip } from "@mui/material";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import {
  setActivityTypes,
  setCurrencies,
  setRequestsStatuses,
} from "../../redux/slices/requestSlice";
import { apiRequestWithRefresh } from "../../services/apiRequestWithRefresh";
import SnackbarFail from "../../reusables/SnackbarFail";
import { statusConfig } from "../../data/requestsConfig";
import SnackbarWarning from "../../reusables/SnackbarWarning";
import Request from "../../components/Request/Request";
import SnackbarSuccess from "../../reusables/SnackbarSuccess";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const initFilters = {
  number_request: null,
  agent_title: "",
  contract_title: "",
  status: "",
  currency: "",
  activity_type_title: "",
  request_date: null,
  date_update: null,
  total: "",
  curs: "",
};

const AllRequestsPage = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(false);

  const [snackbarText, setSnackbarText] = useState("");
  const [snackbarFailOpen, setSnackbarFailOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarSuccessOpen, setSnackbarSuccessOpen] = useState(false);

  const [allRequests, setAllRequests] = useState([]);
  const [filters, setFilters] = useState(initFilters);
  const [sortOrder, setSortOrder] = useState("asc");
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [fileDeletionStatus, setFileDeletionStatus] = useState({
    4: false,
    10: false,
    9: false,
    8: false,
  });

  const [headers, setHeaders] = useState(
    allHeaders.map((header) => ({ ...header, filterIsOpen: false }))
  );
  const [anchorEl, setAnchorEl] = useState(null);

  const authData = useSelector((state) => state.auth.authData);
  const isAuth = useSelector((state) => state.auth.isAuth);
  const company = useSelector((state) => state.agent.company);
  const requestStatuses = useSelector((state) => state.request.requestStatuses);
  const currencies = useSelector((state) => state.request.currencies);
  const activityTypes = useSelector((state) => state.request.activityTypes);
  const requestDocTypes = useSelector((state) => state.request.requestDocTypes);

  const dispatch = useDispatch();

  // const navigate = useNavigate();
  // const location = useLocation();

  const { setNavbarDisabled, handleRequesttModalOpen } =
    useContext(ModalsContext);

  // Fetching requests and request-related data
  useEffect(() => {
    if (isAuth && company) {
      const fetchAllRequests = async (newAuthData = null) => {
        // setPageLoading(true);
        try {
          setNavbarDisabled(true);
          let statuses;
          let currencies;
          let activityTypes;

          const dataCurrency = await requestAPI.get_currency(
            newAuthData || authData
          );
          if (dataCurrency.statusText === "OK") {
            const pickedCurrencies = ["RUB", "USD", "EUR", "CNY"];
            currencies = dataCurrency.data.filter((currency) =>
              pickedCurrencies.includes(currency.currency_title)
            );
            dispatch(setCurrencies(currencies));
          }

          const responseActivityTypes = await requestAPI.get_activity_type(
            newAuthData || authData
          );
          if (responseActivityTypes.statusText === "OK") {
            activityTypes = responseActivityTypes.data;
            dispatch(setActivityTypes(responseActivityTypes.data));
          }

          const dataStatuses = await requestAPI.get_request_statuses(
            newAuthData || authData
          );
          if (dataStatuses.statusText === "OK") {
            statuses = dataStatuses.data;
            dispatch(setRequestsStatuses(statuses));
          }

          const response = await requestAPI.get_requests(
            newAuthData || authData
          );
          if (response.statusText === "OK") {
            const responseData = response.data;
            responseData.sort(
              (a, b) => new Date(b.date_update) - new Date(a.date_update)
            );
            const requestsData = responseData.map((request) =>
              updateRequest(request, statuses, currencies, activityTypes)
            );
            setAllRequests(requestsData);
          }
          setPageLoading(false);
          setNavbarDisabled(false);
        } catch (err) {
          if (err.response && err.response.status === 401) {
            try {
              const newAuthData = await authAPI.refreshToken(authData);
              if (newAuthData && newAuthData.data.access) {
                const updatedAuthData = newAuthData.data;
                Cookies.set("authData", JSON.stringify(updatedAuthData), {
                  secure: true,
                });
                dispatch(setAuthData(updatedAuthData));
                fetchAllRequests(updatedAuthData);
              }
            } catch (refreshError) {
              if (
                refreshError &&
                (refreshError.response.status === 401 ||
                  refreshError.response.status === 400)
              ) {
                console.log("Error refreshing token:", refreshError);
                Cookies.remove("authData");
                dispatch(setIsAuth(false));
                dispatch(setExpirationWarnOpen(true));
                dispatch(
                  setExpirationMessage(
                    "Текущая сессия истекла. Пожалуйста, авторизуйтесь заново"
                  )
                );
                setPageLoading(false);
                setNavbarDisabled(false);
              }
            }
          } else {
            console.error("Error fetching requests:", err);
            setPageLoading(false);
            setNavbarDisabled(false);
          }
        }
      };
      fetchAllRequests();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuth, dispatch, company]);

  const triggerFileInput = (inputId) => {
    document.getElementById(inputId).click();
  };

  const handleCloseFail = () => {
    setSnackbarFailOpen(false);
  };

  const handleCloseSuccess = () => {
    setSnackbarSuccessOpen(false);
  };

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const deleteFilters = () => {
    setFilters(initFilters);
  };

  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleFilterOpen = (isOpen, headerName, event) => {
    setHeaders((prevHeaders) =>
      prevHeaders.map((header) =>
        header.name === headerName
          ? { ...header, filterIsOpen: isOpen }
          : { ...header }
      )
    );
    setAnchorEl(isOpen ? event.currentTarget : null);
  };

  const filteredRows = allRequests.filter((row) => {
    return (
      (filters.number_request
        ? row.number_request === parseInt(filters.number_request)
        : true) &&
      (filters.agent_title
        ? row.agent_title.includes(filters.agent_title)
        : true) &&
      (filters.contract_title
        ? row.contract_title.includes(filters.contract_title)
        : true) &&
      (filters.status ? row.status.includes(filters.status) : true) &&
      (filters.currency
        ? String(row.currency).includes(filters.currency)
        : true) &&
      (filters.activity_type_title
        ? String(row.activity_type_title).includes(filters.activity_type_title)
        : true) &&
      (filters.request_date
        ? row.request_date === filters.request_date.format("DD.MM.YYYY")
        : true) &&
      (filters.date_update
        ? row.date_update === filters.date_update.format("DD.MM.YYYY")
        : true) &&
      (filters.total ? String(row.total).includes(filters.total) : true) &&
      (filters.curs ? String(row.curs).includes(filters.curs) : true)
    );
  });

  const sortRequests = (header) => {
    setAllRequests((prevRequests) => {
      const sortedRequests = [...prevRequests].sort((a, b) => {
        const parseDate = (dateStr) => {
          const [day, month, year] = dateStr.split(".");
          return new Date(`${year}-${month}-${day}`);
        };
        if (sortOrder === "asc") {
          return parseDate(a[header]) - parseDate(b[header]);
        } else {
          return parseDate(b[header]) - parseDate(a[header]);
        }
      });
      return sortedRequests;
    });

    setSortOrder((prevOrder) => (prevOrder === "asc" ? "desc" : "asc"));
  };

  const updateRequest = (request, statuses, currencies, activityTypes) => {
    const foundStatus = statuses?.find(
      (status) => status.id === request.request_status
    );
    const foundCurrency = currencies?.find(
      (currency) => currency.id === request.currency_pay
    );
    const foundActType = activityTypes?.find(
      (type) => type.id === request.activity_type
    );

    return {
      ...request,
      status: foundStatus?.status_title,
      currency: foundCurrency?.currency_title,
      activity_type_title: foundActType?.type_title || "---",
      request_date: moment(request.request_date).format("DD.MM.YYYY"),
      date_update: moment(request.date_update).format("DD.MM.YYYY"),
      total: request.total && parseInt(request.total, 10),
    };
  };

  const handleFileChange = (event, fileDocType) => {
    const uid = "id" + new Date().getTime();
    const file = event.target.files[0];

    if (!file) {
      setSnackbarFailOpen(true);
      setSnackbarText("Ошибка в выборе файла");
      return;
    }

    if (file) {
      if (file.size > 50000000) {
        setSnackbarFailOpen(true);
        setSnackbarText("Размер файла превышает 50 МБ");
        return;
      }
    }

    if (
      !file.type.includes("msword") &&
      !file.type.includes("officedocument.wordprocessingml.document") &&
      fileDocType === 9
    ) {
      setSnackbarFailOpen(true);
      setSnackbarText("Пожалуйста, загрузите word файл");
      return;
    }

    if (!file.type.includes("pdf") && fileDocType === 8) {
      setSnackbarFailOpen(true);
      setSnackbarText("Пожалуйста, загрузите pdf файл");
      return;
    }

    setSelectedFiles([
      ...selectedFiles,
      { id: uid, file: file, docType: fileDocType },
    ]);
  };

  const handleChangeUpdateInputs = (event, field) => {
    let value = event.target.value;

    setSelectedRequest({
      ...selectedRequest,
      [field]: value,
    });
  };

  const deleteFile = async (file_id, docType) => {
    setFileDeletionStatus((prev) => ({
      ...prev,
      [docType]: true,
    }));
    setNavbarDisabled(true);
    try {
      const data = await apiRequestWithRefresh(
        requestAPI.delete_req_file,
        authData,
        dispatch,
        file_id
      );
      if (data.statusText === "OK") {
        const updatedRequest = {
          ...selectedRequest,
          files: selectedRequest.files.filter((file) => file.id !== file_id),
        };
        setSelectedRequest(updatedRequest);
      }
      setSnackbarSuccessOpen(true);
      setFileDeletionStatus((prev) => ({
        ...prev,
        [docType]: false,
      }));
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages);
      setFileDeletionStatus((prev) => ({
        ...prev,
        [docType]: false,
      }));
      setNavbarDisabled(false);
    }
  };

  const deleteFileUI = (id) => {
    setSelectedFiles((prevFiles) => prevFiles.filter((file) => file.id !== id));
  };

  const goBackToRequests = () => {
    setSelectedRequest(null);
    // let currentPath;
    // if (isRequestRedirect.current) {
    //   currentPath = "/all-requests/";
    // } else {
    //   currentPath = location.pathname;
    // }
    // navigate(currentPath, { replace: true });
    // isRequestRedirect.current = false;
  };

  // const handleRowClick = async (row) => {
  //   const { agent, number_contract, id } = row;
  //   if (agent && number_contract && id) {
  //     const url = `${redirectURL}/requests/?agent_id=${agent}&contract_id=${number_contract}&req_id=${id}`;
  //     window.location.href = url;
  //   }
  // };

  const handleRowClick = async (row) => {
    try {
      setPageLoading(true);
      setNavbarDisabled(true);

      const response = await apiRequestWithRefresh(
        requestAPI.get_request,
        authData,
        dispatch,
        row.number_contract,
        row.id
      );
      if (response.statusText === "OK") {
        const requestData = response.data;
        const updatedNewRequest = updateRequest(
          requestData,
          requestStatuses,
          currencies,
          activityTypes
        );
        setSelectedRequest(updatedNewRequest);
      }
      setPageLoading(false);
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages || "");
      setPageLoading(false);
      setNavbarDisabled(false);
    }
  };

  const handleUpdateRequest = async () => {
    const toBase64 = (file) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result.split(",")[1]);
        reader.onerror = (error) => reject(error);
      });

    const filesArray = [];

    for (const fileInfo of selectedFiles) {
      const file = fileInfo.file;
      const docType = fileInfo.docType;

      if (file) {
        const base64File = await toBase64(file);
        const filesObj = {
          file_base64: base64File,
          file_name: file.name,
          file_type: file.type,
          doc_type: docType,
        };
        filesArray.push(filesObj);
      }
    }

    const config = statusConfig[selectedRequest.request_status];

    if (selectedRequest.request_status === 2 && !selectedRequest.total) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, укажите сумму");
      return;
    }

    const dataToDB = {
      request_status: config.nextStatus,
      comment_client: selectedRequest.comment_client,
      total: selectedRequest.total,
      currency_pay: selectedRequest.currency_pay,
      files: filesArray,
    };

    try {
      setRequestLoading(true);
      setNavbarDisabled(true);

      const data = await apiRequestWithRefresh(
        requestAPI.update_request,
        authData,
        dispatch,
        dataToDB,
        selectedRequest.id
      );

      if (data.statusText === "OK") {
        const updatedRequest = data.data;
        const updatedNewRequest = updateRequest(
          updatedRequest,
          requestStatuses,
          currencies,
          activityTypes
        );
        setSelectedRequest(updatedNewRequest);
        setAllRequests((prev) =>
          prev.map((item) =>
            item.id === updatedNewRequest.id ? updatedNewRequest : item
          )
        );
      }
      setSnackbarSuccessOpen(true);
      setSelectedFiles([]);
      setRequestLoading(false);
      setNavbarDisabled(false);
    } catch (errorMessages) {
      setSnackbarFailOpen(true);
      setSnackbarText(errorMessages);
      setRequestLoading(false);
      setNavbarDisabled(false);
    }
  };

  return (
    <div className={styles.container}>
      {pageLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="#6367b1d4"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        <>
          {Object.values(filters).some((value) => value) && (
            <div className={styles.headerContainer}>
              <div className={styles.headerText}>
                {Object.keys(filters).map((item, index) => {
                  const header = headers.find((header) => header.name === item);
                  return (
                    header &&
                    filters[item] && (
                      <React.Fragment key={index}>
                        <div className={styles.label}>{header.label}:</div>
                        <div className={styles.text}>
                          {item !== "request_date" && item !== "date_update"
                            ? filters[item]
                            : filters[item].format("DD.MM.YYYY") ===
                              "Invalid Date"
                            ? "Выберите дату"
                            : filters[item].format("DD.MM.YYYY")}
                        </div>
                      </React.Fragment>
                    )
                  );
                })}
              </div>
              <Tooltip
                title="Удалить фильтры"
                className={styles.iconDelete}
                onClick={deleteFilters}
              >
                <DeleteForeverIcon className={styles.icon} />
              </Tooltip>
            </div>
          )}
          {allRequests.length > 0 && !selectedRequest && (
            <AllRequestsTable
              headers={headers}
              rows={allRequests}
              handleFilterChange={handleFilterChange}
              filteredRows={filteredRows}
              filters={filters}
              handleFilterOpen={handleFilterOpen}
              anchorEl={anchorEl}
              handleRowClick={handleRowClick}
              sortRequests={sortRequests}
            />
          )}
        </>
      )}

      {!pageLoading && (
        <div className={styles.buttonContainer}>
          {selectedRequest && (
            <Button
              variant="outlined"
              startIcon={<ArrowBackIcon />}
              sx={{
                // minWidth: "140px",
                minHeight: "40px",
                color: "rgba(28, 107, 31, 0.7)",
                borderColor: "rgba(28, 107, 31, 0.7)",
                "&:hover": {
                  color: "rgba(28, 107, 31, 0.8)",
                  borderColor: "rgba(28, 107, 31, 0.7)",
                },
              }}
              onClick={goBackToRequests}
            >
              Вернуться к заявкам
            </Button>
          )}
        </div>
      )}

      {requestLoading ? (
        <div className={styles.loaderContainer}>
          <ThreeCircles
            visible={true}
            height="130"
            width="130"
            color="rgba(28, 107, 31, 0.7)"
            ariaLabel="triangle-loading"
          />
        </div>
      ) : (
        selectedRequest &&
        !pageLoading && (
          <Request
            headers={requestOptions}
            item={selectedRequest}
            handleOpen={handleRequesttModalOpen}
            currencies={currencies}
            triggerFileInput={triggerFileInput}
            handleFileChange={handleFileChange}
            requestLoading={requestLoading}
            handleChangeUpdateInputs={handleChangeUpdateInputs}
            handleUpdateRequest={handleUpdateRequest}
            statusConfig={statusConfig}
            requestDocTypes={requestDocTypes}
            deleteFile={deleteFile}
            fileDeletionStatus={fileDeletionStatus}
            selectedFiles={selectedFiles}
            deleteFileUI={deleteFileUI}
          />
        )
      )}

      {allRequests.length === 0 && !pageLoading && !selectedRequest && (
        <div className={styles.warnText}>
          <DoNotDisturbOnIcon className={styles.rotatingIcon} />
          <span>Заявок нет</span>
        </div>
      )}

      <SnackbarFail
        snackbarOpen={snackbarFailOpen}
        handleClose={handleCloseFail}
        snackbarText={snackbarText}
      />
      <SnackbarSuccess
        snackbarOpen={snackbarSuccessOpen}
        handleClose={handleCloseSuccess}
        snackbarText="Заявка обновлена"
      />
      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default AllRequestsPage;

import React from "react";
import { Chip } from "@mui/material";

const ReusableChip = ({
  borderColor,
  backgroundColor,
  color,
  label,
  minHeight,
  minWidth,
}) => {
  return (
    <Chip
      label={label}
      sx={{
        minHeight,
        minWidth,
        fontSize: "15px",
        borderWidth: "2px",
        borderColor,
        backgroundColor,
        color,
      }}
    />
  );
};

export default ReusableChip;

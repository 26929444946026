import styles from "./AllRequestsTable.module.scss";

import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  Select,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";

import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { ruRU } from "@mui/x-date-pickers/locales";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "dayjs/locale/ru";
import FilterModal from "./FilterModal/FilterModal";
import SwapVertIcon from "@mui/icons-material/SwapVert";

dayjs.extend(customParseFormat);

dayjs.locale("ru");

const breakpointsValues = {
  xxxs: 320,
  xxs: 360,
  xs: 400,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920,
};

const mainColor = "#8b8fd763";

const theme = createTheme(
  {
    breakpoints: {
      values: breakpointsValues,
    },
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            minWidth: "100px",
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: "grey",
            fontSize: "0.95rem",
            fontFamily: "Inter",
            top: "-5px",
            "&.Mui-focused": {
              color: mainColor,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderWidth: "1px",
              borderColor: "#ccc",
              textTransform: "none",
              backgroundColor: "#fcfcfc",
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: mainColor,
            },
          },
        },
      },

      MuiInputBase: {
        styleOverrides: {
          input: {
            zIndex: 10001,
          },
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            "&.MuiPickersDay-root.Mui-selected": {
              backgroundColor: mainColor,
            },
            "&.Mui-selected": {
              backgroundColor: mainColor,
            },
          },
        },
      },

      MuiSvgIcon: {
        styleOverrides: {
          root: {
            zIndex: 10001,
          },
        },
      },
      MuiPickersLayout: {
        styleOverrides: {
          root: {
            [`@media (max-width:${breakpointsValues.xs}px)`]: {
              width: "260px",
            },
            [`@media (max-width:${breakpointsValues.xxs}px)`]: {
              width: "230px",
            },
            [`@media (max-width:${breakpointsValues.xxxs}px)`]: {
              width: "200px",
            },
          },
        },
      },
    },
  },
  ruRU
);

const AllRequestsTable = ({
  headers,
  rows,
  handleFilterChange,
  filteredRows,
  filters,
  handleFilterOpen,
  anchorEl,
  handleRowClick,
  sortRequests,
}) => {
  return (
    <div className={styles.container}>
      <TableContainer
        component={Paper}
        sx={{
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: 3,
        }}
      >
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) => (
                <TableCell
                  key={index}
                  sx={{
                    backgroundColor: "#666aad",
                    color: "#fff",
                    fontWeight: "bold",
                    textTransform: "uppercase",
                    minWidth: "100px",
                  }}
                >
                  <div className={styles.labelContainer}>
                    {header.label}
                    {header.filterIsOpen ? (
                      <div
                        className={`${styles.icon} ${
                          header.filterIsOpen ? styles.open : ""
                        }`}
                        onClick={(e) => handleFilterOpen(false, header.name, e)}
                      >
                        <KeyboardArrowUpIcon className={styles.arrow} />
                      </div>
                    ) : (
                      <div
                        className={styles.icon}
                        onClick={(e) => handleFilterOpen(true, header.name, e)}
                      >
                        <KeyboardArrowUpIcon className={styles.arrow} />
                      </div>
                    )}
                  </div>

                  {header.filterIsOpen &&
                    (header.name === "agent_title" ||
                    header.name === "contract_title" ||
                    header.name === "status" ||
                    header.name === "currency" ||
                    header.name === "activity_type_title" ? (
                      <FilterModal
                        open={header.filterIsOpen}
                        onClose={(e) => handleFilterOpen(false, header.name, e)}
                        anchorEl={anchorEl}
                      >
                        <Select
                          value={filters[header.name]}
                          onChange={(e) =>
                            handleFilterChange(header.name, e.target.value)
                          }
                          displayEmpty
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#fcfcfc",
                            minWidth: "100px",
                          }}
                        >
                          <MenuItem value="">
                            <div>Все</div>
                          </MenuItem>
                          {[
                            ...new Set(rows.map((row) => row[header.name])),
                          ].map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      </FilterModal>
                    ) : header.name === "request_date" ||
                      header.name === "date_update" ? (
                      <FilterModal
                        open={header.filterIsOpen}
                        onClose={(e) => handleFilterOpen(false, header.name, e)}
                        anchorEl={anchorEl}
                      >
                        <div className={styles.filterModal}>
                          <ThemeProvider theme={theme}>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                              adapterLocale="ru"
                            >
                              <DatePicker
                                value={dayjs(filters[header.name])}
                                onChange={(newValue) => {
                                  handleFilterChange(header.name, newValue);
                                }}
                                format="DD.MM.YYYY"
                                mask=""
                                defaultValue={null}
                                disableMaskedInput
                              />
                            </LocalizationProvider>
                          </ThemeProvider>
                          <div
                            className={styles.sort}
                            onClick={() => sortRequests(header.name)}
                          >
                            <Tooltip title="Сортировать по датам">
                              <SwapVertIcon />
                            </Tooltip>
                          </div>
                        </div>
                      </FilterModal>
                    ) : (
                      <FilterModal
                        open={header.filterIsOpen}
                        onClose={(e) => handleFilterOpen(false, header.name, e)}
                        anchorEl={anchorEl}
                      >
                        <TextField
                          value={filters[header.name] || ""}
                          onChange={(e) =>
                            handleFilterChange(header.name, e.target.value)
                          }
                          placeholder={`Введите значение`}
                          variant="outlined"
                          size="small"
                          sx={{
                            textTransform: "none",
                            backgroundColor: "#fcfcfc",
                            minWidth: "100px",
                          }}
                        />
                      </FilterModal>
                    ))}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row, index) => (
              <TableRow key={index} hover onClick={() => handleRowClick(row)}>
                <TableCell component="th" scope="row">
                  {row.number_request}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={styles.tableCell}
                >
                  {row.agent_title}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  className={styles.tableCell}
                >
                  {row.contract_title}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {row.request_date}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {row.date_update}
                </TableCell>
                <TableCell className={styles.tableCell}>{row.status}</TableCell>
                <TableCell className={styles.tableCell}>
                  {row.total ? row.total : "---"}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {row.currency}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {row.activity_type_title ? row.activity_type_title : "---"}
                </TableCell>
                <TableCell className={styles.tableCell}>
                  {row.curs ? row.curs : "---"}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AllRequestsTable;

export const options = [
  {
    label: "Статус",
    type: "Chip",
    name: "Status",
    text: "",
  },
  {
    label: "Плательщик",
    name: "TextCompany",
    type: "Text",
    text: "",
  },
  {
    label: "Комментарий менеджера",
    name: "TextManagerComment",
    type: "Text",
    text: "",
  },
  {
    label: "Номер контракта",
    name: "TextContractNumber",
    type: "Text",
    text: "",
  },
  {
    label: "Внешнеэкономический контракт на учете?",
    name: "is_vbk",
    type: "Chip",
    text: "",
  },
  {
    label: "Комментарий клиента",
    name: "ClientComment",
    type: "TextField",
    text: "Введите свой комментарий",
  },
  // {
  //   label: "Файлы",
  //   name: "Files",
  //   type: "Card",
  //   text: "",
  // },
  // {
  //   label: "Другие файлы",
  //   name: "OtherFiles",
  //   type: "Button",
  //   text: "Выбрать файлы",
  // },
];

export const newContractOptions = [
  {
    label: "Карточка компании плательщика",
    type: "Text",
    text: "",
    name: "profile_name",
  },
  {
    label: "Статус",
    type: "Chip",
    text: "Черновик",
    statusColor: "Gray",
    name: "",
  },
  {
    label: "Номер контракта",
    type: "TextField",
    text: "Введите номер контракта",
    name: "contract_number",
  },
  {
    label: "Внешнеэкономический контракт на учете?",
    type: "Checkbox",
    text: "",
    name: "is_vbk",
  },
  {
    label: "Внешнеэкономический контракт",
    type: "Button",
    text: "Выберите внешнеэкономический контракт",
    name: "contract",
    number: 13,
  },
  {
    label: "ВБК",
    type: "Button",
    text: "Выберите ВБК",
    name: "vbk",
    number: 16,
  },
  {
    label: "ДТ1",
    type: "Button",
    text: "Выберите ДТ1",
    name: "dt1",
    number: 14,
  },
  {
    label: "ДТ2",
    type: "Button",
    text: "Выберите ДТ2",
    name: "dt2",
    number: 15,
  },
  {
    label: "Комментарий клиента",
    type: "TextField",
    text: "Введите свой комментарий",
    name: "comment_client",
  },
];

import React, { useContext } from "react";
import styles from "./Sidebar.module.scss";
import { useNavigate } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import { getSidebarOptions } from "../../services/sidebarOptions";
import ModalsContext from "../../contexts/ModalsContext";

const Sidebar = () => {
  const laptopMediaQuery = useMediaQuery({ maxWidth: 1500 });

  const navigate = useNavigate();

  const profiles = useSelector((state) => state.agent.profiles);
  const company = useSelector((state) => state.agent.company);
  const contract = useSelector((state) => state.contract.contract);

  const profileStatus = company?.pay_profile_status;
  const contractStatus = contract?.contract_status;

  const drawerWidth = laptopMediaQuery ? 200 : 250;

  const { navbarDisabled } = useContext(ModalsContext);

  const sidebarOptions = getSidebarOptions(
    profiles,
    profileStatus,
    contractStatus,
    navbarDisabled
  );

  const handleSidebarNavigation = (option) => {
    switch (option) {
      case "Profile":
        navigate("/");
        break;
      case "Contracts":
        navigate("/contracts");
        break;
      case "Requests":
        navigate("/requests");
        break;
      case "AllRequests":
        navigate("/all-requests");
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.container}>
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            top: "64px",
          },
        }}
        open
      >
        <List>
          {sidebarOptions.map((item, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton
                onClick={() => handleSidebarNavigation(item.label)}
                disabled={item.disabled}
              >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default Sidebar;

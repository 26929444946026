import React from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const SnackbarSuccess = ({ handleClose, snackbarText, snackbarOpen }) => {
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={snackbarOpen}
      autoHideDuration={4000}
      onClose={handleClose}
      action={action}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
    >
      <Alert
        onClose={handleClose}
        severity="success"
        sx={{
          width: "100%",
          fontSize: "16px",
          alignItems: "center",
        }}
        iconMapping={{
          success: <CheckCircleIcon fontSize="inherit" />,
        }}
      >
        {snackbarText}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarSuccess;

import styles from "./BodyHeaderProfile.module.scss";
import OutlinedInput from "@mui/material/OutlinedInput";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ReusableChip from "../../ReusableChip/ReusableChip";
import { getProfileStatusStyles } from "../../../services/getProfileStatusStyles";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    sx: {
      "& .MuiMenuItem-root.Mui-selected": {
        minWidth: "900px",
      },
      "& .MuiMenuItem-root:hover": {
        minWidth: "900px",
      },
      "& .MuiMenuItem-root.Mui-selected:hover": {
        minWidth: "900px",
      },
    },
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
      overflowX: "auto",
    },
  },
};

const BodyHeaderProfile = ({
  chosenOption,
  onChangeFunc,
  list,
  handleOpen,
  text,
  buttonText,
  profileStatus,
  loading,
}) => {
  return (
    <div className={styles.header}>
      <div className={styles.h2}>
        <h2>{text}</h2>
      </div>
      <FormControl
        sx={{ m: 1, width: 600, minWidth: 200 }}
        disabled={(!list || loading) && true}
      >
        <InputLabel>{text}</InputLabel>
        <Select
          value={list && list.length > 0 ? chosenOption?.id || "" : ""}
          onChange={onChangeFunc}
          input={<OutlinedInput label="Выберите компанию" />}
          MenuProps={MenuProps}
        >
          {list &&
            list.map((item, index) => (
              <MenuItem
                key={index}
                value={item.id}
                sx={{
                  display: "flex", // Растягиваем элемент на всю ширину
                  "&:hover": {
                    width: "100%", // Растягиваем элемент при hover на всю ширину
                  },
                }}
              >
                {item.full_name}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {!loading &&
        list &&
        profileStatus &&
        (() => {
          const styles = getProfileStatusStyles(profileStatus.id);
          if (!styles) return null;
          return (
            <ReusableChip
              borderColor={styles.borderColor}
              backgroundColor={styles.backgroundColor}
              color={styles.color}
              label={profileStatus.status_title}
              minHeight="50px"
              minWidth="none"
            />
          );
        })()}

      <Button
        variant="contained"
        endIcon={<AddIcon />}
        sx={{
          minWidth: "140px",
          minHeight: "50px",
        }}
        onClick={handleOpen}
        disabled={loading}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default BodyHeaderProfile;

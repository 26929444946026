import {
  FormControlLabel,
  FormGroup,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import styles from "./ProfileManagers.module.scss";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import { addManager, updateManager } from "../../redux/slices/agentSlice";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import DeleteIcon from "@mui/icons-material/Delete";

const ProfileManagers = ({ handleDeleteCompanyManager, loading }) => {
  const companyManagers = useSelector((state) => state.agent.companyManagers);
  const company = useSelector((state) => state.agent.company);

  const dispatch = useDispatch();

  const handleFieldChange = (id, key, value) => {
    dispatch(updateManager({ id, key, value }));
  };

  const handleAddNewUser = () => {
    dispatch(
      addManager({
        uid: "id" + new Date().getTime(),
        manager_email: "",
        company: company.id,
        comp_admin: false,
      })
    );
  };

  return (
    <div className={styles.body}>
      <div className={styles.iconContainer} onClick={handleAddNewUser}>
        <Tooltip title="Добавить нового пользователя">
          <PersonAddAltOutlinedIcon className={styles.icon} />
        </Tooltip>
      </div>
      <div className={styles.line} />

      {companyManagers.map((manager) => (
        <React.Fragment key={manager.id || manager.uid}>
          <ManagerInfo
            manager={manager}
            onChange={handleFieldChange}
            id={manager.id || manager.uid}
            handleDeleteCompanyManager={handleDeleteCompanyManager}
            loading={loading}
          />
        </React.Fragment>
      ))}
    </div>
  );
};

const ManagerInfo = ({
  manager,
  onChange,
  id,
  handleDeleteCompanyManager,
  loading,
}) => {
  return (
    <>
      <div className={styles.infoContainer}>
        <TextField
          label="Email"
          placeholder="Введите email"
          color="warning"
          sx={{
            width: "350px",
            minWidth: "200px",
            "@media (max-width: 600px)": {
              width: "100%",
              minWidth: "none",
            },
            "& input[type=number]": {
              MozAppearance: "textfield",
            },
            "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button":
              {
                WebkitAppearance: "none",
                margin: 0,
              },
          }}
          value={manager.manager_email}
          onChange={(e) => onChange(id, "manager_email", e.target.value)}
          type="text"
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                color="warning"
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                checked={manager.comp_admin}
                name="comp_admin"
                onChange={(e) => onChange(id, "comp_admin", e.target.checked)}
              />
            }
            label="Администратор"
          />
        </FormGroup>
        <button
          className={styles.deleteIconContainer}
          onClick={() => handleDeleteCompanyManager(manager)}
          disabled={loading}
        >
          <Tooltip title="Удалить">
            <DeleteIcon className={styles.icon} />
          </Tooltip>
        </button>
      </div>
      {/* {index + 1 !== companyManagers.length && ( */}
      <div className={styles.line} />
      {/* )} */}
    </>
  );
};

export default ProfileManagers;

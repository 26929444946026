import {
  removeCompanyId,
  removeProfiles,
  removeProfileStatus,
} from "../redux/slices/agentSlice";
import {
  removeContractId,
  removeContracts,
  removeContractStatus,
} from "../redux/slices/contractSlice";
import {
  removeRequests,
  removeRequestsStatuses,
} from "../redux/slices/requestSlice";

export const clearAppState = (dispatch) => {
  dispatch(removeProfiles());
  dispatch(removeCompanyId());
  dispatch(removeProfileStatus());
  dispatch(removeContractStatus());
  dispatch(removeContracts());
  dispatch(removeContractId());
  dispatch(removeRequests());
  dispatch(removeRequestsStatuses());
};

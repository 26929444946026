export const options = [
  {
    label: "ИНН компании",
    placeholder: "7712345678",
    name: "inn_company",
    type: "text",
  },
  {
    label: "Полное наименование",
    placeholder: "Общество с ограниченной ответственностью 'Весна'",
    name: "full_name",
    type: "text",
  },
  {
    label: "Юридический адрес",
    placeholder: "123456, г.Москва, ул. Подвойского, д.14, стр.7",
    name: "address_company",
    type: "text",
  },
  {
    label: "Почтовый адрес",
    placeholder: "123456, г.Москва, ул. Подвойского, д.14, стр.7",
    name: "mail_address_company",
    type: "text",
  },
  {
    label: "КПП компании",
    placeholder: "779101001",
    name: "kpp_company",
    type: "text",
  },
  {
    label: "ОКВЭД (основной)",
    placeholder: "18.15",
    name: "okved_company",
    type: "text",
  },
  {
    label: "ОГРН компании",
    placeholder: "1047712345678",
    name: "ogrn_company",
    type: "text",
  },
  {
    label: "БИК банка",
    placeholder: "044521234",
    name: "bik_company",
    type: "text",
  },
  {
    label: "Наименование банка",
    placeholder: "Московский банк ПАО Сбербанк г.Москва",
    name: "name_bank",
    type: "text",
  },
  {
    label: "Р/С клиента в банке",
    placeholder: "40702810123450101230",
    name: "check_company",
    type: "text",
  },
  {
    label: "К/С банка",
    placeholder: "30101234500000000225",
    name: "korrespondent_company",
    type: "text",
  },
  {
    label: "ОКПО компании",
    placeholder: "12345678",
    name: "okpo_company",
    type: "text",
  },
  {
    label: "ОКАТО компании",
    placeholder: "87654321000",
    name: "okato_company",
    type: "text",
  },
  {
    label: "Генеральный директор",
    placeholder: "Петров Сергей Сергеевич",
    name: "director_company",
    type: "text",
  },
  {
    label: "Электронная почта",
    placeholder: "info@vesna.ru",
    name: "email_company",
    type: "text",
  },
  {
    label: "Телефон",
    placeholder: "+749512345678",
    name: "phone_company",
    type: "text",
  },
  {
    label: "Сайт",
    placeholder: "www.vesna.ru",
    name: "site_company",
    type: "text",
  },
  { label: "Комиссия", placeholder: "", name: "comission", type: "text" },
  {
    label: "Комментарий по согласованной комиссии",
    placeholder: "",
    name: "comment",
    type: "text",
  },
];

export const getRequestStatusStyles = (statusId) => {
  switch (statusId) {
    case 1: // Запрос курса
      return {
        borderColor: "rgba(158, 158, 158, 0.5)",
        backgroundColor: "rgba(158, 158, 158, 0.1)",
        color: "rgba(66, 66, 66, 0.8)",
      };
    case 2: // Курс согласован
      return {
        borderColor: "rgba(33, 150, 243, 0.5)",
        backgroundColor: "rgba(33, 150, 243, 0.1)",
        color: "rgba(0, 81, 148, 0.8)",
      };
    case 3: // Заявка на подписании
      return {
        borderColor: "rgba(0, 121, 191, 0.5)",
        backgroundColor: "rgba(0, 121, 191, 0.1)",
        color: "rgba(0, 51, 102, 0.8)",
      };
    case 4: // Заявка подписана
      return {
        borderColor: "rgba(255, 193, 7, 0.5)",
        backgroundColor: "rgba(255, 193, 7, 0.1)",
        color: "rgba(255, 152, 0, 0.8)",
      };
    case 5: // Счет на оплату
      return {
        borderColor: "rgba(76, 175, 80, 0.5)",
        backgroundColor: "rgba(76, 175, 80, 0.1)",
        color: "rgba(56, 142, 60, 0.8)",
      };
    case 6: // Оплата поставщику в процессе
      return {
        borderColor: "rgba(255, 152, 0, 0.5)",
        backgroundColor: "rgba(255, 152, 0, 0.1)",
        color: "rgba(251, 140, 0, 0.8)",
      };
    case 7: // Оплата поставщику произведена
      return {
        borderColor: "rgba(103, 58, 183, 0.5)",
        backgroundColor: "rgba(103, 58, 183, 0.1)",
        color: "rgba(81, 45, 168, 0.8)",
      };
    case 8: // Поставщик получил средства
      return {
        borderColor: "rgba(0, 188, 212, 0.5)",
        backgroundColor: "rgba(0, 188, 212, 0.1)",
        color: "rgba(0, 150, 136, 0.8)",
      };
    case 9: // Агентский отчет на подписании
      return {
        borderColor: "rgba(139, 195, 74, 0.5)",
        backgroundColor: "rgba(139, 195, 74, 0.1)",
        color: "rgba(104, 159, 56, 0.8)",
      };
    case 10: // Услуга завершена
      return {
        borderColor: "rgba(0, 150, 136, 0.5)",
        backgroundColor: "rgba(0, 150, 136, 0.1)",
        color: "rgba(0, 121, 107, 0.8)",
      };
    case 11: // Возврат средств
      return {
        borderColor: "rgba(255, 87, 34, 0.5)",
        backgroundColor: "rgba(255, 87, 34, 0.1)",
        color: "rgba(216, 67, 21, 0.8)",
      };
    case 12: // Отменена
      return {
        borderColor: "rgba(244, 67, 54, 0.5)",
        backgroundColor: "rgba(244, 67, 54, 0.1)",
        color: "rgba(198, 40, 40, 0.8)",
      };
    default:
      return null;
  }
};

import styles from "./ResetPasswordPage.module.scss";
import LOGO from "../../assets/icons/LOGO.svg";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Backdrop, Box, Button, Fade, Modal, Typography } from "@mui/material";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import { authAPI } from "../../api/authAPI";
import SnackbarWarning from "../../reusables/SnackbarWarning";

const ResetPasswordPage = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const handleCloseSnack = () => {
    setSnackbarOpen(false);
  };

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const isAuth = useSelector((state) => state.auth.isAuth);

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите пароль");
      return;
    }

    if (password !== confirmPassword) {
      setSnackbarOpen(true);
      setSnackbarText("Пароли не совпадают");
      return;
    }

    try {
      setLoading(true);
      const data = await authAPI.reset_password({
        password,
        token,
      });
      console.log("data", data);
      if (data.data.status === "OK") {
        handleOpen();
      }
      setLoading(false);
    } catch (error) {
      let errorMessages = [];
      if (error.response) {
        const messages = error.response.data;
        Object.values(messages).forEach((item) => errorMessages.push(item));
        if (error.response.status === 404) {
          setSnackbarText("Данная ссылка больше не активна");
        }
      } else {
        throw errorMessages.push("Не удается установить соединение с сервером");
      }
      setSnackbarOpen(true);
      setSnackbarText(errorMessages.join(" "));
      setLoading(false);
    }
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.imgContainer}>
          <img alt="logo" src={LOGO} />
        </div>
        <div className={styles.content}>
          <h1>Платежный агент ILC PAY</h1>
          <div className={styles.form}>
            <div className={styles.inputContainer}>
              <span className={styles.spanText}>Сбросить пароль</span>
              <input
                className={styles.input}
                type="password"
                placeholder="Введите новый пароль"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                className={styles.input}
                type="password"
                placeholder="Подтвердите новый пароль"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <button className={styles.buttonLogin} type="submit">
                {loading ? (
                  <DotsSpinner auth />
                ) : (
                  <span className={styles.spanText2}>Отправить</span>
                )}
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box className={styles.modal}>
            <div className={styles.modalContent}>
              <Typography variant="h6" sx={{ mt: 2, mb: 1 }}>
                Пароль успешно обновлен
              </Typography>
              <Typography variant="body2" sx={{ mb: 3 }}>
                Используйте его для входа в личный кабинет
              </Typography>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate("/auth")}
                sx={{}}
              >
                Ок
              </Button>
            </div>
          </Box>
        </Fade>
      </Modal>
      <SnackbarWarning
        handleClose={handleCloseSnack}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default ResetPasswordPage;

export const headers = [
  { label: "Номер заявки", name: "number_request", type: "Text", text: "" },
  { label: "Дата заявки", name: "request_date", type: "Text", text: "" },
  {
    label: "Статус заявки",
    name: "status",
    type: "Chip",
    text: "",
  },
  {
    label: "Сумма",
    name: "total",
    type: "TextField",
    text: "Введите сумму",
  },
  { label: "Валюта", name: "currency_pay", type: "Select", text: "" },
  {
    label: "Вид деятельности",
    name: "activity_type",
    type: "Chip_activity",
    text: "",
  },
  { label: "Согласованный курс", name: "curs", type: "Text", text: "" },
];

export const allHeaders = [
  { label: "Номер заявки", name: "number_request", type: "Text", text: "" },
  { label: "Компания", name: "agent_title", type: "Text", text: "" },
  { label: "Контракт", name: "contract_title", type: "Text", text: "" },
  { label: "Дата создания", name: "request_date", type: "Text", text: "" },
  { label: "Дата обновления", name: "date_update", type: "Text", text: "" },
  {
    label: "Статус заявки",
    name: "status",
    type: "Chip",
    text: "",
  },
  {
    label: "Сумма",
    name: "total",
    type: "TextField",
    text: "Введите сумму",
  },
  { label: "Валюта", name: "currency", type: "Select", text: "" },
  {
    label: "Вид деятельности",
    name: "activity_type_title",
    type: "Select",
    text: "",
  },
  { label: "Согласованный курс", name: "curs", type: "Text", text: "" },
];

export const requestOptions = [
  ...headers,
  { label: "Комментарий менеджера", name: "comment", type: "Text", text: "" },
  {
    label: "Комментарий клиента",
    name: "comment_client",
    type: "TextField",
    text: "Комментарий клиента",
  },
];

export const requestButtons = [
  {
    text: "Выберите инвойс от поставщика",
    name: "invoice",
    label: "Инвойс от поставщика",
    docType: 4,
  },
  {
    text: "Выберите другие файлы",
    name: "Others",
    label: "Другие файлы",
    docType: 10,
  },
];

export const newRequestOptions = [
  {
    label: "Контракт",
    type: "Text",
    name: "contract_name",
    text: "839848239489834849849'",
  },
  {
    label: "Статус заявки",
    type: "Chip",
    text: "Запрос курса",
    name: "request_status",
  },
  {
    label: "Валюта",
    type: "Select",
    text: "RUB",
    name: "currency_pay",
  },
  {
    label: "Комментарий клиента",
    type: "TextField",
    text: "Введите свой комментарий",
    name: "comment_client",
  },
];

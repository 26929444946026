import ReactInputMask from "react-input-mask";
import styles from "./RegistrationPage.module.scss";
import LOGO from "../../assets/icons/LOGO.svg";
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setAuthData, setIsAuth, setName } from "../../redux/slices/authSlice";
import { authAPI } from "../../api/authAPI";
import { useState } from "react";
import Cookies from "js-cookie";
import DotsSpinner from "../../components/Loaders/DotsSpinner/DotsSpinner";
import SnackbarWarning from "../../reusables/SnackbarWarning";
import { options } from "../../data/registrationOptions";
import { clearAppState } from "../../services/clearAppState";

const RegistrationPage = () => {
  const [userData, setUserData] = useState({
    first_name: "",
    last_name: "",
    password: "",
    confirm_password: "",
    email: "",
    phone_number: "",
  });
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarText, setSnackbarText] = useState("");

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const isAuth = useSelector((state) => state.auth.isAuth);

  const handleClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangeData = (event) => {
    const { name, value } = event.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handlePhoneChange = (event) => {
    const phoneNumber = event.target.value.replace(/\D/g, "");

    setUserData((prevData) => ({
      ...prevData,
      phone_number: phoneNumber,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (userData.password !== userData.confirm_password) {
      setSnackbarOpen(true);
      setSnackbarText("Пароли не совпадают");
      return;
    }
    if (userData.password.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите пароль");
      return;
    }
    if (userData.email.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите ваш email");
      return;
    }
    if (userData.phone_number.length < 11) {
      setSnackbarOpen(true);
      setSnackbarText("Номер телефона должен содержать 11 цифр");
      return;
    }
    if (userData.first_name.length === 0 || userData.last_name.length === 0) {
      setSnackbarOpen(true);
      setSnackbarText("Пожалуйста, введите ваше имя");
      return;
    }

    try {
      setLoading(true);
      const response = await authAPI.register(userData);

      if (response.statusText === "Created") {
        const data = await authAPI.login(userData.email, userData.password);

        if (data.statusText === "OK") {
          const authData = data.data;
          Cookies.set("authData", JSON.stringify(authData), { secure: true });
          clearAppState(dispatch);
          dispatch(setIsAuth(true));
          dispatch(setAuthData(authData));
          dispatch(
            setName({
              first_name: authData.first_name,
              last_name: authData.last_name,
            })
          );
        }
      }
      setLoading(false);
    } catch (error) {
      let errorMessages = [];
      if (error.response) {
        const messages = error.response.data;
        Object.values(messages).forEach((item) => errorMessages.push(item));
      } else {
        throw errorMessages.push("Не удается установить соединение с сервером");
      }
      setSnackbarOpen(true);
      setSnackbarText(errorMessages.join(" "));
      setLoading(false);
    }
  };

  if (isAuth) {
    return <Navigate to="/" />;
  }

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.imgContainer}>
          <img alt="logo" src={LOGO} />
        </div>
        <div className={styles.content}>
          <h1>Платежный агент ILC PAY</h1>
          <div className={styles.form}>
            <div className={styles.inputContainer}>
              <span className={styles.spanText}>Регистрация</span>
              {options.map((option, index) => (
                <input
                  className={styles.input}
                  placeholder={option.placeholder}
                  name={option.name}
                  type={option.type}
                  value={userData[option.name]}
                  onChange={(e) => handleChangeData(e)}
                  key={index}
                />
              ))}
              <ReactInputMask
                mask="+7 (999) 999-99-99"
                maskChar={null}
                name="phone_number"
                value={userData.phone_number}
                className={styles.input}
                placeholder="Телефон"
                onChange={(e) => handlePhoneChange(e)}
              />

              <button
                className={styles.button}
                type="submit"
                aria-label="Нажмите, чтобы зарегистрироваться"
              >
                {loading ? (
                  <DotsSpinner auth />
                ) : (
                  <span className={styles.spanText2}>Зарегистрироваться</span>
                )}
              </button>
              <div className={styles.textLink}>
                <button onClick={() => navigate("/auth")} type="button">
                  Уже зарегистрирован
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <SnackbarWarning
        handleClose={handleClose}
        snackbarText={snackbarText}
        snackbarOpen={snackbarOpen}
      />
    </div>
  );
};

export default RegistrationPage;

import { useDispatch, useSelector } from "react-redux";
import styles from "./Header.module.scss";
import { CiLogout } from "react-icons/ci";
import { authAPI } from "../../api/authAPI";
import Cookies from "js-cookie";
import { useContext, useState } from "react";
import Spinner from "../Loaders/Spinner/Spinner";
import LOGO from "../../assets/icons/LOGO.svg";
import { apiRequestWithRefresh } from "../../services/apiRequestWithRefresh";
import { setIsAuth } from "../../redux/slices/authSlice";
import { useMediaQuery } from "react-responsive";
import ModalsContext from "../../contexts/ModalsContext";

const Header = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const { setNavbarDisabled } = useContext(ModalsContext);

  const smallMediaQuery = useMediaQuery({ maxWidth: 430 });

  const authData = useSelector((state) => state.auth.authData);
  const name = useSelector((state) => state.auth.name);
  // console.log("name", name);

  const handleLogout = async () => {
    try {
      setLoading(true);
      setNavbarDisabled(true);
      const response = await apiRequestWithRefresh(
        authAPI.logout,
        authData,
        dispatch
      );
      if (response.status === 205) {
        Cookies.remove("authData");
        dispatch(setIsAuth(false));
      } else {
        console.log(`Unexpected status code: ${response.status}`);
      }
      setLoading(false);
      setNavbarDisabled(false);
    } catch (error) {
      if (error && error.response.status === 400) {
        Cookies.remove("authData");
        dispatch(setIsAuth(false));
        console.log("Failed to logout:", error);
      }
      console.error(error);
    }
  };

  return (
    <header className={styles.header}>
      <button onClick={handleLogout}>
        {loading ? <Spinner /> : <CiLogout className={styles.logout} />}
      </button>
      <div className={styles.imageContainer}>
        <img src={LOGO} alt="" />
        <div className={styles.text}>Платежный агент ILC PAY</div>
      </div>
      {!smallMediaQuery ? (
        <div
          className={styles.name}
        >{`${name.first_name} ${name.last_name}`}</div>
      ) : (
        <div className={styles.name}> </div>
      )}
    </header>
  );
};

export default Header;

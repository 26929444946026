import { ReactComponent as PdfIcon } from "../assets/icons/pdf.svg";
import { ReactComponent as WordIcon } from "../assets/icons/word.svg";
import { ReactComponent as ExcelIcon } from "../assets/icons/excel.svg";
import { ReactComponent as ImageIcon } from "../assets/icons/image.svg";
import { ReactComponent as FileIcon } from "../assets/icons/file.svg";

export const fileIcons = {
  pdf: <PdfIcon width="25px" height="25px" />,
  word: <WordIcon width="25px" height="25px" />,
  excel: <ExcelIcon width="25px" height="25px" />,
  image: <ImageIcon width="25px" height="25px" />,
  unknown: <FileIcon width="25px" height="25px" />,
};

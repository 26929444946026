import React from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

const PrivateRoute = ({ element }) => {
  const isAuth = useSelector((state) => state.auth.isAuth);

  return isAuth ? element : <Navigate to="/auth" />;
};
export default PrivateRoute;

import React, { useContext } from "react";
import styles from "./NavBar.module.scss";
import { useNavigate } from "react-router-dom";
import { getSidebarOptions } from "../../services/sidebarOptions";
import { useSelector } from "react-redux";
import ModalsContext from "../../contexts/ModalsContext";

const NavBar = () => {
  const navigate = useNavigate();

  const profiles = useSelector((state) => state.agent.profiles);
  const company = useSelector((state) => state.agent.company);
  const contract = useSelector((state) => state.contract.contract);

  const profileStatus = company?.pay_profile_status;
  const contractStatus = contract?.contract_status;

  const { navbarDisabled } = useContext(ModalsContext);

  const navbarOptions = getSidebarOptions(
    profiles,
    profileStatus,
    contractStatus,
    navbarDisabled
  );

  const handleNavbarNavigation = (option) => {
    switch (option) {
      case "Profile":
        navigate("/");
        break;
      case "Contracts":
        navigate("/contracts");
        break;
      case "Requests":
        navigate("/requests");
        break;
      case "AllRequests":
        navigate("/all-requests");
        break;
      default:
        return;
    }
  };

  return (
    <div className={styles.navbar}>
      {navbarOptions.map((item, index) => (
        <button
          key={index}
          className={`${styles.item} ${item.disabled ? styles.disabled : ""}`}
          onClick={() => handleNavbarNavigation(item.label)}
          disabled={item.disabled}
        >
          {item.icon}
          {item.text}
        </button>
      ))}
    </div>
  );
};

export default NavBar;

import { FormControlLabel, FormGroup, Switch } from "@mui/material";
import styles from "./CompanySettings.module.scss";
import React from "react";

const CompanySettings = ({ handleChangeSettings, settings }) => {
  return (
    <div className={styles.body}>
      <h2>Настройки рассылок</h2>
      {Object.keys(settings).map((key) => (
        <React.Fragment key={key}>
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  color="warning"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  checked={settings[key]}
                  onChange={(e) => handleChangeSettings(key, e.target.checked)}
                />
              }
              label={
                key === "comp_message_send"
                  ? "Рассылка по профилю"
                  : key === "contract_message_send"
                  ? "Рассылка по контракту"
                  : "Рассылка по заявке"
              }
            />
          </FormGroup>
          <div className={styles.line} />
        </React.Fragment>
      ))}
    </div>
  );
};

export default CompanySettings;

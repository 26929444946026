import { createContext, useRef, useState } from "react";

const ModalsContext = createContext();

export const ModalsContextProvider = ({ children }) => {
  const [navbarDisabled, setNavbarDisabled] = useState(false);
  const [newProfileModalOpen, setnewProfileModalOpen] = useState(false);
  const [newContractModalOpen, setNewContractModalOpen] = useState(false);
  const [newRequestModalOpen, setNewRequestModalOpen] = useState(false);
  const isRequestRedirect = useRef(false);

  const handleProfileModalOpen = () => setnewProfileModalOpen(true);
  const handleProfileModalClose = () => setnewProfileModalOpen(false);

  const handleContractModalOpen = () => setNewContractModalOpen(true);
  const handleContractModalClose = () => setNewContractModalOpen(false);

  const handleRequesttModalOpen = () => setNewRequestModalOpen(true);
  const handleRequestModalClose = () => setNewRequestModalOpen(false);

  return (
    <ModalsContext.Provider
      value={{
        newProfileModalOpen,
        newContractModalOpen,
        newRequestModalOpen,
        handleProfileModalOpen,
        handleProfileModalClose,
        handleContractModalOpen,
        handleContractModalClose,
        handleRequesttModalOpen,
        handleRequestModalClose,
        navbarDisabled,
        setNavbarDisabled,
        isRequestRedirect,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};

export default ModalsContext;

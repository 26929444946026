import RecentActorsRoundedIcon from "@mui/icons-material/RecentActorsRounded";
import StickyNote2RoundedIcon from "@mui/icons-material/StickyNote2Rounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import TableChartSharpIcon from "@mui/icons-material/TableChartSharp";

export const getSidebarOptions = (
  profiles,
  profileStatus,
  contractStatus,
  navbarDisabled
) => [
  {
    label: "Profile",
    text: "Профили компаний",
    icon: <RecentActorsRoundedIcon />,
    disabled: navbarDisabled ? true : false,
  },
  {
    label: "Contracts",
    text: "Контракты",
    icon: <StickyNote2RoundedIcon />,
    disabled:
      profiles.length > 0 && profileStatus === 3 && !navbarDisabled
        ? false
        : true,
  },
  {
    label: "Requests",
    text: "Заявки",
    icon: <EditNoteRoundedIcon />,
    disabled:
      profiles.length > 0 &&
      profileStatus === 3 &&
      (contractStatus === 7 || contractStatus === 5) &&
      !navbarDisabled
        ? false
        : true,
  },
  {
    label: "AllRequests",
    text: "Все заявки",
    icon: <TableChartSharpIcon />,
    disabled: navbarDisabled ? true : false,
  },
];

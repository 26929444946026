import axios from "axios";
import { daDataURL, tokenBIK, tokenINN } from "../env";

const instance = axios.create({
  baseURL: daDataURL,
  headers: {},
});

export const daDataAPI = {
  async getDataByINN(query) {
    try {
      const response = await instance.post(
        `/dadata_inn/`,
        { query },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Token " + tokenINN,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting data by INN:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get data by INN. Check console for details.");
    }
  },
  async getDataByBIK(query) {
    try {
      const response = await instance.post(
        `/dadata_bank/`,
        { query },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: "Token " + tokenBIK,
          },
        }
      );
      console.log("response", response);

      return response;
    } catch (err) {
      console.error(
        "Error getting data by BIK:",
        err.response ? err.response.data : err
      );
      console.log("Failed to get data by BIK. Check console for details.");
    }
  },
};

import styles from "./DotsSpinner.module.scss";

const DotsSpinner = ({ auth }) => {
  return (
    <div className={styles.spinner} data-is-auth={auth ? "true" : "false"}>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
      <div className={styles.dot}></div>
    </div>
  );
};

export default DotsSpinner;
